<template>
  <div id="app-container" class="row align-items-center justify-content-center">
    <div class="terminal-bg">
      <section id="terminal">
        <router-view />
      </section>
    </div>
    <div class="text">
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#SercemzUkrainą!</span>
      </h2>
      <h1>Wesprzyj zwierzęta pokrzywdzone w wyniku wojny w Ukrainie!</h1>
      <p>
        Skorzystaj z terminala Donateo i przekaż datek, by pomóc zwierzętom,
        które Fundacja Viva ratuje z Ukrainy oraz tym, które trafiają do Polski
        wraz ze swoimi opiekunami. Zebrane środki wesprą także zbiórkę na rzecz
        zakupu karmy i leków dla ukraińskich schronisk, przytulisk i organizacji
        działających na miejscu.
      </p>
      <p>Dzięki Twojemu wsparciu Fundacja Viva! sfinansuje:</p>
      <ul>
        <li>bezpieczne schronienie dla uratowanych zwierząt</li>
        <li>transportery i klatki do przewozu zwierząt</li>
        <li>leczenie</li>
        <li>karmę</li>
        <li>obroże, smycze, szelki</li>
        <li>leki, w tym preparaty przeciwko pchłom, kleszczom</li>
        <li>czipy</li>
        <li>miski, posłania, budy</li>
        <li>żwirek i kuwety dla kotów.</li>
      </ul>
      <p>
        Twoja wpłata trafi bezpośrednio na konto<br />
        <strong>Fundacji Viva!</strong>
      </p>
      <p>Dziękujemy!</p>
      <img :src="require('@/assets/img/logo.svg')" alt="" />
    </div>
    <div class="mobile-text" v-if="showText">
      <div>
        <img :src="require('@/assets/img/logo.svg')" alt="" />
      </div>
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#SercemzUkrainą!</span>
      </h2>
      <h1>Wesprzyj zwierzęta pokrzywdzone w wyniku wojny w Ukrainie!</h1>
      <p>
        Skorzystaj z terminala Donateo i przekaż datek, by pomóc zwierzętom,
        które Fundacja Viva ratuje z Ukrainy oraz tym, które trafiają do Polski
        wraz ze swoimi opiekunami. Zebrane środki wesprą także zbiórkę na rzecz
        zakupu karmy i leków dla ukraińskich schronisk, przytulisk i organizacji
        działających na miejscu.
      </p>
      <p>Dzięki Twojemu wsparciu Fundacja Viva! sfinansuje:</p>
      <ul>
        <li>bezpieczne schronienie dla uratowanych zwierząt</li>
        <li>transportery i klatki do przewozu zwierząt</li>
        <li>leczenie</li>
        <li>karmę</li>
        <li>obroże, smycze, szelki</li>
        <li>leki, w tym preparaty przeciwko pchłom, kleszczom</li>
        <li>czipy</li>
        <li>miski, posłania, budy</li>
        <li>żwirek i kuwety dla kotów.</li>
      </ul>
      <p>
        Twoja wpłata trafi bezpośrednio na konto<br />
        <strong>Fundacji Viva!</strong>
      </p>
      <p>Dziękujemy!</p>
    </div>
    <div class="ibtn" v-on:click="showText = !showText">
      <img :src="require('@/assets/img/i_btn.png')" alt="" v-if="!showText" />
      <img :src="require('@/assets/img/x_btn.png')" alt="" v-if="showText" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showText: false,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/App.css";
</style>
